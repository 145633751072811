/**
 * This is Functional component for Loader
*/
import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SpinWrapper } from './style';

interface LoaderProps {
  children?: React.ReactNode | undefined
  wrapperClassName?: string
  loadingMessage?: string
  size?: 'large' | 'medium' | 'small'
  isOverlayLoader?: boolean
}

const Loader: FC<LoaderProps> = ({
  children,
  isOverlayLoader,
  loadingMessage,
  size,
  wrapperClassName
}) => {
  return (
    <SpinWrapper className={`${isOverlayLoader ? 'loader-overlay' : ''} ${wrapperClassName ?? ''} ${size ?? ''}`}>
      <Spin
        size="large"
        indicator={<LoadingOutlined spin />}
        tip={loadingMessage}
      >
        {children && children}
      </Spin>
    </SpinWrapper>
  );
};

Loader.defaultProps = {
  size: 'large',
  isOverlayLoader: true
}

Loader.propTypes = {
  children: PropTypes.node,
  wrapperClassName: PropTypes.string,
  loadingMessage: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  isOverlayLoader: PropTypes.bool
};

export default Loader;
